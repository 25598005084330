import request from '@/service/request'


// 获取教室列表
export const getClassroomList = (params) => {
  return request.post('/app/teachClassroom/list', params)
}

// 删除教室
export const delClassroomList = (id) => {
  return request.post('/app/teachClassroom/del/' + id)
}

// 根据id查询教室
export const getClassroomById = (id) => {
  return request.post('/app/teachClassroom/getById/' + id)
}

// 新增/保存教室
export const saveClassroomList = (id) => {
  return request.post('/app/teachClassroom/save', id)
}

export const saveClassroomApply = (id) => {
  return request.post('/app/teachClassroomApply/save', id)
}

// 获取教室使用申请 page
export const pageClassroomApplyApi = (params) => {
  return request.post('/app/teachClassroomApply/page', params)
}

// 获取申请教室列表
export const getClassroomApplyList = (params) => {
  return request.post('/app/teachClassroomApply/list', params)
}
