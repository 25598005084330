<template>
  <el-dialog
    class="reject-reason"
    title="驳回申请"
    :visible.sync="show"
    :close-on-click-modal="false"
    :before-close="cancel"
    width="620px"
  >
    <el-descriptions direction="vertical" :column="3" border>
      <el-descriptions-item label="申请人">
        {{ readData.stuName }}
      </el-descriptions-item>
      <el-descriptions-item label="申请人班级" label-class-name="descriptions-item__label" v-if="readData.classesName">
        {{ readData.classesName }}
      </el-descriptions-item>
      <el-descriptions-item label="申请人部门" label-class-name="descriptions-item__label"
                            v-else-if="readData.deptName">
        {{ readData.deptName }}
      </el-descriptions-item>
      <el-descriptions-item label="申请人院系" label-class-name="descriptions-item__label"
                            v-else-if="readData.collegeName">
        {{ readData.collegeName }}
      </el-descriptions-item>
      <el-descriptions-item label="申请人电话" label-class-name="descriptions-item__label"
                            v-else-if="readData.phone">
        {{ readData.collegeName }}
      </el-descriptions-item>
      <el-descriptions-item label="申请使用教室" label-class-name="descriptions-item__label">
        <b>{{ readData.teachBuildName }}{{ readData.classroomName }}</b>
      </el-descriptions-item>
    </el-descriptions>
    <el-form class="mt-1" ref="form" :model="formData" label-width="120px">
      <el-form-item label="驳回原因">
        <el-input type="textarea" rows="3" v-model.trim="formData.auditText" />
      </el-form-item>
    </el-form>
    <template #footer>
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
      <el-button type="danger" @click="save('教室使用申请？',saveClassroomApply,'已驳回')">确认驳回</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { saveClassroomApply } from '@/api/teachClassroom'
import dialog from '@/vue/mixins/dialog'

export default {
  mixins: [dialog],
  data () {
    return {
      preTitle: '驳回',
      readData: {
        stuName: '',
        teachBuildName: '',
        classroomName: '',
        classesName: '', // 申请人 班级 互斥
        collegeName: '', // 申请人  院系 互斥
        deptName: '', // 申请人 部门 互斥
        phone: '' // 申请人 电话
      },
      formData: {
        id: null,
        auditStatus: 2,
        auditText: ''
      }
    }
  },
  methods: {
    saveClassroomApply // 保存api
  }
}
</script>
<style lang="scss">
.reject-reason {
  .el-descriptions {
    .descriptions-item__label {
      text-align: left !important;
      width: 220px;
    }
  }
}
</style>
