var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-view',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"total":_vm.total,"query-info":_vm.queryInfo},on:{"update:queryInfo":function($event){_vm.queryInfo=$event},"update:query-info":function($event){_vm.queryInfo=$event},"reload-table":_vm.renderTable},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('view-search-form',{attrs:{"label-width":"55px","queryInfo":_vm.queryInfo,"filter-keys":['managerUserId']},on:{"update:queryInfo":function($event){_vm.queryInfo=$event},"update:query-info":function($event){_vm.queryInfo=$event},"on-search":_vm.renderTable}},[_c('el-form-item',{attrs:{"label":"校区","prop":"campusId"}},[_c('el-select',{attrs:{"clearable":"","filterable":"","size":"small"},on:{"change":_vm.campusChecked},model:{value:(_vm.queryInfo.campusId),callback:function ($$v) {_vm.$set(_vm.queryInfo, "campusId", $$v)},expression:"queryInfo.campusId"}},_vm._l((_vm.campusList),function(ref){
var campusName = ref.campusName;
var id = ref.id;
return _c('el-option',{key:id,attrs:{"label":campusName,"value":id}})}),1)],1),_c('el-form-item',{attrs:{"label":"楼栋","prop":"buildId"}},[_c('el-select',{attrs:{"clearable":"","filterable":"","size":"small"},on:{"change":_vm.buildChecked},model:{value:(_vm.queryInfo.buildId),callback:function ($$v) {_vm.$set(_vm.queryInfo, "buildId", $$v)},expression:"queryInfo.buildId"}},_vm._l((_vm.buildList),function(ref){
var value = ref.value;
var label = ref.label;
return _c('el-option',{key:value,attrs:{"label":label,"value":value}})}),1)],1),_c('el-form-item',{attrs:{"label":"楼层","prop":"floorNo"}},[_c('el-select',{attrs:{"clearable":"","filterable":"","size":"small"},on:{"change":_vm.floorChange},model:{value:(_vm.queryInfo.floorNo),callback:function ($$v) {_vm.$set(_vm.queryInfo, "floorNo", $$v)},expression:"queryInfo.floorNo"}},_vm._l((_vm.floorList),function(num){return _c('el-option',{key:num,attrs:{"label":("第" + num + "层"),"value":num}})}),1)],1),_c('el-form-item',{attrs:{"label":"课室","prop":"classroomId"}},[_c('el-select',{attrs:{"clearable":"","filterable":"","size":"small"},model:{value:(_vm.queryInfo.classroomId),callback:function ($$v) {_vm.$set(_vm.queryInfo, "classroomId", $$v)},expression:"queryInfo.classroomId"}},_vm._l((_vm.classRoomList),function(ref){
var label = ref.label;
var id = ref.id;
return _c('el-option',{key:id,attrs:{"label":label,"value":id}})}),1)],1),_c('el-form-item',{attrs:{"label":"审核状态","prop":"auditStatus","label-width":"70px"}},[_c('el-select',{attrs:{"clearable":"","filterable":"","size":"small"},model:{value:(_vm.queryInfo.auditStatus),callback:function ($$v) {_vm.$set(_vm.queryInfo, "auditStatus", $$v)},expression:"queryInfo.auditStatus"}},_vm._l((_vm.$store.state.systemParam.reviewStatus),function(ref){
var paramValue = ref.paramValue;
var paramName = ref.paramName;
return _c('el-option',{key:paramValue,attrs:{"label":paramName,"value":paramValue}})}),1)],1)],1)]},proxy:true}])},[_c('el-table',{attrs:{"data":_vm.tableData,"border":"","stripe":""}},[_c('el-table-column',{attrs:{"type":"index","label":"ID","width":"50"}}),_c('el-table-column',{attrs:{"prop":"teachBuildName","label":"所在楼栋","width":"100"}}),_c('el-table-column',{attrs:{"prop":"classroomName","label":"申请课室","width":"100","align":"center"}}),_c('el-table-column',{attrs:{"prop":"stuName","label":"申请人","width":"100"}}),_c('el-table-column',{attrs:{"label":"申请人班级/院系/部门","min-width":"230"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.classesName ? row.classesName : (row.deptName ? row.deptName : row.collegeName ? row.collegeName : ''))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"申请人电话","prop":"phone","min-width":"140","align":"center"}}),_c('el-table-column',{attrs:{"label":"开始时间","width":"180","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(row.startTime,'YYYY-MM-DD HH:mm'))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"结束时间","width":"180","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(row.endTime,'YYYY-MM-DD HH:mm'))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"applyReason","label":"申请理由","min-width":"200"}}),_c('el-table-column',{attrs:{"prop":"auditText","label":"驳回原因","min-width":"200"}}),_c('el-table-column',{attrs:{"prop":"auditStatus","label":"申请状态","width":"100","fixed":"right","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.auditStatus)?_c('el-tag',{attrs:{"type":_vm.$store.state.systemParam.reviewStatus[row.auditStatus-1].btnType}},[_vm._v(" "+_vm._s(_vm.$store.state.systemParam.reviewStatus[row.auditStatus - 1].paramName)+" ")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"操作","fixed":"right","width":"160"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.auditStatus === 1)?[_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['class:review']),expression:"['class:review']"}],attrs:{"type":"success","size":"small"},on:{"click":function($event){return _vm.handleApprove(row,3)}}},[_vm._v("通过 ")]),_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['class:review']),expression:"['class:review']"}],attrs:{"type":"danger","size":"small"},on:{"click":function($event){return _vm.handleApprove(row, 2)}}},[_vm._v("驳回 ")])]:_vm._e()]}}])})],1),(_vm.visibleDialog)?_c('reject-reason',{ref:"dialogRef",attrs:{"visible":_vm.visibleDialog},on:{"update:visible":function($event){_vm.visibleDialog=$event},"on-close":_vm.renderTable}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }